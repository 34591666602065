.contact {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.contact-container {
  height: fit-content;
}
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
}
.name-input,
.email-input,
.message-input {
  border: 3px solid #cca961;
  width: 300px;
  height: 40px;
  padding-left: 5px;
  border-radius: 5px;
}

.btn {
  all: unset;
  background-color: #000;
  border: 2px solid #cca951;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background-color: #cca961;
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  width: 100%;
  height: 100px;
  background-color: #000;
}

.contact-img {
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
  .iframe-google-maps {
    width: 400px;
    height: 300px;
  }
}
