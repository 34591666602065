@import url("https://fonts.cdnfonts.com/css/lemonmilk");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lemon/Milk light", sans-serif;
  font-weight: 300;
}

:root {
  --mainColor: #cca961;
  --blackColor: #000000;
  --secondaryColor: #a97d42;
  --textColor: #caa961;
}

a {
  text-decoration: underline;
  color: var(--mainColor);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: var(--blackColor);
  color: var(--textColor);
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  font-weight: 600;
  font-size: 30px;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: absolute;
    z-index: 3;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--blackColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}
