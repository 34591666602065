.footer-container {
  background-color: black;
  color: #cca961;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo img {
  width: 150px;
  height: auto;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.footer-section {
  width: 100%;

  .footer-section h2 {
    font-size: 1.2em;
  }

  .footer-section a {
    color: #cca961;
  }

  .footer-copyright {
    margin-top: auto;
  }

  @media (min-width: 768px) {
    .footer-container {
      display: flex;
      flex-direction: column;
    }
    .footer-links {
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }

    .footer-section {
      width: calc((100% - 160px - 80px) / 3);
    }
  }
}
