/* src/components/MedusaSection.css */

.medusa-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: #cca961;
  padding: 50px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
}

.medusa-content {
  max-width: 45%;
  padding-right: 20px;
  animation: slideInLeft 1.2s ease-in-out;
}

.medusa-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.medusa-content h1::before,
.medusa-content h1::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(204, 169, 97, 0.3) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: smoke 6s ease-in-out infinite;
}

.medusa-content p {
  font-size: 1.2em;
  line-height: 1.5;
}

.medusa-image {
  position: relative;
  animation: float 3s ease-in-out infinite;
}

.medusa-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  animation: fadeInZoom 1.5s ease-in-out;
}

/* Keyframes for animations */
@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes smoke {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .medusa-container {
    flex-direction: column;
    padding: 20px;
  }

  .medusa-content {
    max-width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .medusa-content h1 {
    font-size: 2.5em;
  }

  .medusa-content p {
    font-size: 1.1em;
  }

  .medusa-image {
    display: flex;
    justify-content: center;
  }

  .medusa-image img {
    max-width: 80%;
  }
}
