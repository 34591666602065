body {
  background-color: #000;
}
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 10px;
  padding: 20px;
}

.image-gallery img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 2px solid #cca961;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background: #000;
  padding: 20px;
  border-radius: 8px;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
}

.modal button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn {
  all: unset;
  background-color: #cca961;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
